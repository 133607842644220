<template>
    <main id="liste" v-cloak>
        <HeaderTab :title="$t('monte.carnet_saillie')"/>

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                         <div class="box">
                        	<div class="row">
	                         	<div class="col-6">
		                         	<e-select
						                v-model="seasonSelected"
						                id="seasons"
						                track-by="name"
						                label="name"
						                class="primary"
						                :options="seasons"
						                :searchable="true"
						                :show-labels="false"
						                :allow-empty="false"
						                @input="changeSeason()"
						            >
						                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
						            </e-select>
						        </div>

	                         	<div class="col-6">
						            <e-select
						                v-model="entitySelected"
						                id="entities"
						                track-by="tiers_rs"
						                label="tiers_rs"
						                class="primary"
						                :options="entities"
						                :searchable="true"
						                :show-labels="false"
						                :allow-empty="false"
						                :placeholder="placeholder_entity"
						                @input="changeEntity()"
						            >
						            	<template slot="option" slot-scope="{ option }">{{ option.tiers_rs }} ({{ option.tiers_stallion_number || $t('monte.stallion_number_not_set') }})</template>
						                <template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }} ({{ option.tiers_stallion_number || $t('monte.stallion_number_not_set') }})</template>
						                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
						            </e-select>
						        </div>
						    </div>

						    <div class="row">
						        <div v-if="entitySelected && seasonSelected && dps_status && dps_status.last_status" class="col-auto mr-auto mt-3">
					        		<span>
					        			{{ $t('monte.dps_status.'+dps_status.last_status) }} {{ date_update_dps }}
					        		</span>
					        		 <span v-if="nb_errors > 0">- {{ nb_errors }} {{ $t('monte.erreur_carnet') }}</span>
						        </div>
						        <div v-if="entitySelected && seasonSelected" class="col-auto ml-auto mt-3">
						        	<template v-if="dps_status">
						        		<button v-if="dps_status.horsedpsstatus_status != 0" class="btn btn-secondary rounded-pill" @click="prepareCarnet">
											{{ $t('monte.prepare_carnet') }}
											<font-awesome-icon :icon="['fal', 'sync']"/>
										</button>

										<button v-if="dps_status.horsedpsstatus_status == 3" class="btn btn-secondary rounded-pill ml-2" @click="openModalEnvoi">
											{{ $t('monte.envoie_carnet') }}
											<font-awesome-icon v-if="!envoie_working" :icon="['fal', 'upload']"/>
											<font-awesome-icon v-else :icon="['fal', 'spinner']" spin />
										</button>

										<button v-if="dps_status.horsedpsstatus_status == 4" class="btn btn-secondary rounded-pill ml-2" @click="importCarnet">
											{{ $t('monte.recup_result') }}
											<font-awesome-icon v-if="!import_working" :icon="['fal', 'download']"/>
											<font-awesome-icon v-else :icon="['fal', 'spinner']" spin />
										</button>
											
										<button v-if="dps_status.horsedpsstatus_status == 0" class="btn btn-secondary rounded-pill" @click="importCarnet">
											{{ $t('monte.import_carnet') }}
											<font-awesome-icon v-if="!import_working" :icon="['fal', 'download']"/>
											<font-awesome-icon v-else :icon="['fal', 'spinner']" spin />
										</button>
						        	</template>

						        	<button v-else class="btn btn-secondary rounded-pill" @click="importCarnet">
										{{ $t('monte.import_carnet') }}
										<font-awesome-icon v-if="!import_working" :icon="['fal', 'download']"/>
										<font-awesome-icon v-else :icon="['fal', 'spinner']" spin />
									</button>
						        </div>
						    </div>

					        <CustomTable
					            id_table="carnet_saillie"
					            :items="carnet_saillie"
					            :busy.sync="table_busy"
					            primaryKey="horsedps_num"
								:rawColumns="rawColumns"
						        :hrefsRoutes="config_table_hrefs"
				                :externSlotColumns="['horsedps_jument_lieu', 'horsedps_jument_proprio']"
					        >
								<template v-slot:[`custom-slot-cell(horsedps_jument_lieu)`]="{ data }">
									<div :class="data.customClasses.horsedps_jument_lieu"><span v-b-tooltip.html.hover.left :title="data.horsedps_lieu_error">{{ data.horsedps_jument_lieu }}</span></div>
								</template>
								<template v-slot:[`custom-slot-cell(horsedps_jument_proprio)`]="{ data }">
									<div :class="data.customClasses.horsedps_jument_proprio"><span v-b-tooltip.html.hover.left :title="data.horsedps_proprio_error">{{ data.horsedps_jument_proprio }}</span></div>
								</template>
								<!-- <template v-slot:[`custom-slot-cell(sanitaire)`]="{ data }">
									<div :class="data.customClasses.sanitaire"><span v-b-tooltip.html.hover.left :title="data.horsedps_sanitaire_error">{{ data.sanitaire }}</span></div>
								</template> -->
						    </CustomTable>
					    </div>
                    </div>
                </div>
            </div>
        </div>
        <StallionMare 
        	v-if="dps_status"
        	ref="stallion_mare" 
        	:season_id="seasonSelected.id" 
        	:dps_id="dps_status.horsedpsstatus_id"
        	@dps_prepared="changeSeason"
        />
        <ModalConfirm 
			ref="modal_envoi" 
			id="1"
			:icon="['fal', 'upload']"
			:text_title="$t('monte.dps_confirm_title')"
			:text_question="$t('monte.dps_confirm_message')"
			:text_button_ok="$t('monte.envoie_carnet')"
			:text_button_cancel="$t('global.annuler')"
			:callback_validation="envoieCarnet"
			:callback_cancel="closeModalEnvoie" />
    </main>
</template>

<script type="text/javascript">
	import Vue from 'vue'
    import Contract from "@/mixins/Contract.js"
    import DPS from "@/mixins/DPS.js"
    import Gynecologie from "@/mixins/Gynecologie.js"
    import Tiers from "@/mixins/Tiers.js"

	export default {
		name: 'CarnetSaillie',
		mixins: [Contract, DPS, Tiers, Gynecologie],
		data () {
			return {
				seasons: [],
                seasonSelected: {id:null},
                entities: [],
                entitySelected: null,
                placeholder_entity: this.getTrad('monte.placeholder_entity'),
                import_working: false,
                envoie_working: false,
                carnet_saillie: [],
                table_busy: false,
                nb_errors: 0,
                dps_status: {horsedpsstatus_id:null},
				rawColumns: ['horsedps_jument_lieu', 'horsedps_jument_proprio', 'horsedps_send', 'horsedps_validate', 'horsedps_mare_sire', 'horsedps_jument_nom', 'sanitaire', 'horsedps_error', 'horsedps_proprio_error'],
				config_table_hrefs: {
					'horsedps_stallion_name': {
						routeUniqueName: 'StallionFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'horsedps_stallion_id'
						}
					},
					'horsedps_jument_nom': {
						routeUniqueName: 'MareFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'horsedps_jument_id'
						}
					},
				}
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				const seasons = await this.loadSeasons()
                if(seasons) {
                    for(let i = 0; i < seasons.length; i++) {
                        this.seasons.push({
                            name: seasons[i].season_label, 
                            id: seasons[i].season_id,
                            begin: seasons[i].season_start,
                            end: seasons[i].season_end,
                        })
                        if(seasons[i].season_default) {
                            this.seasonSelected = { 
                                name: seasons[i].season_label, 
                                id: seasons[i].season_id,
                                begin: seasons[i].season_start,
                                end: seasons[i].season_end,
                            }
                        }
                    }
                    this.changeSeason()
                }
                const entities = await this.loadTiersEntity()
                this.entities = entities.filter(entity => entity.tiers_stallion_number)
                if(this.entities.length > 0) {
	                this.entitySelected = this.entities[0]
	                this.changeEntity()
	            }
			},

			changeSeason() {
				if(this.entitySelected) {
					this.loadDPS(this.entitySelected.tiers_id, this.seasonSelected.id)
				}
			},

			changeEntity() {
				if(this.seasonSelected) {
					this.loadDPS(this.entitySelected.tiers_id, this.seasonSelected.id)
				}
			},

			async loadDPS(tiers_id, season_id) {
				this.table_busy = true
				const dps = await this.loadCarnetSaillie(tiers_id, season_id)
				this.carnet_saillie = dps.carnet
				this.nb_errors = 0
				if(this.carnet_saillie) {
					this.carnet_saillie.forEach(carte => {
						if(carte.horsedps_jument_nom !== "") {
							carte.horsedps_send = carte.horsedps_send ? this.getTrad('global.oui') : this.getTrad('global.non')
							carte.horsedps_validate = carte.horsedps_validate ? this.getTrad('global.oui') : this.getTrad('global.non')
							carte.horsedps_error = carte.horsedps_error ? this.getTrad('global.oui') : this.getTrad('global.non')
							if(carte.horsedps_tracking) {
								let horsedps_proprio_error = carte.horsedps_tracking.filter(dps => dps.includes('horsedps_proprio'))
								horsedps_proprio_error = horsedps_proprio_error.map(dps => {
									return this.getTrad('monte.dps_error.'+dps)
								})
								carte.horsedps_proprio_error = horsedps_proprio_error.join('<br>')

								let horsedps_lieu_error = carte.horsedps_tracking.filter(dps => dps.includes('horsedps_jument_lieu'))
								horsedps_lieu_error = horsedps_lieu_error.map(dps => {
									return this.getTrad('monte.dps_error.'+dps)
								})
								carte.horsedps_lieu_error = horsedps_lieu_error.join('<br>')

								// let horsedps_sanitaire_error = carte.horsedps_sanitaire.map(dps => {
								// 	return this.getTrad('monte.dps_error.'+dps)
								// })
								// carte.horsedps_sanitaire_error = horsedps_sanitaire_error.join('<br>')
							}
							if(carte.horsedps_tracking !== null) {
								this.nb_errors++
							}
						} else {
							carte.horsedps_send = ''
							carte.horsedps_validate = ''
							carte.horsedps_error = ''
						}
					})
				}
				this.dps_status = dps.status
				this.table_busy = false
			},

			async importCarnet() {
				this.import_working = true
				const import_carnet = await this.importCarnetSaillie(this.entitySelected.tiers_id, this.seasonSelected.id)
				if(import_carnet) {
					this.loadDPS(this.entitySelected.tiers_id, this.seasonSelected.id)
				} else {
					this.failureToast("monte.carnet_not_found")
				}
				this.import_working = false
			},

			async prepareCarnet() {
				this.$refs.stallion_mare.openModal()
			},

			async envoieCarnet() {
				this.closeModalEnvoie()
				this.envoie_working = true
				const envoie_carnet = await this.envoieCarnetSaillie(this.dps_status.horsedpsstatus_id)
				if(envoie_carnet) {
					this.loadDPS(this.entitySelected.tiers_id, this.seasonSelected.id)
				}
				this.envoie_working = false
			},

			openModalEnvoi() {
				this.$refs.modal_envoi.openModal()
			},

			closeModalEnvoie() {
				this.$refs.modal_envoi.closeModal()
			}
		},

		computed: {
			date_update_dps() {
				return Date.parseTz(this.dps_status.last_update).toLocaleDateString(Vue.prototype.$i18n.locale())
			}
		},

		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
            CustomTable : () => import('GroomyRoot/components/Table/CustomTable'), 
            StallionMare: () => import('@/components/DPS/StallionMare'),
            ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm'),
		}
	}

</script>